import React from "react"
import { HardPOSPipedriveForm } from "../../../components/body/pages/general/pipedriveForm"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const HardPOSPipedriveFormPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/business/pos-form/"}
      title="Kuda POS | Request a POS device | Kuda Business"
      description="Apply today to get a Kuda POS machine so you can see all your POS transactions in real-time, receive card payments, transfers and USSD payment and pay for it with flexible payment options"
    />

    <HardPOSPipedriveForm />
  </Layout>
)

export default HardPOSPipedriveFormPage
