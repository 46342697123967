import React from "react";


export const HardPOSPipedriveForm = () => {
    return (
        <div className="pipedriveWebForms"
            style={{
                width: '100%',
                height: '100vh',

            }}
            data-pd-webforms="https://webforms.pipedrive.com/f/6Wg08xsTzge2Cm8T5fX4O0iPzAV6k7UQiI0FXyWEnbEMsi9mT9bOwmgd78nsNdEGEH"
        >


            <iframe
                src={`https://webforms.pipedrive.com/f/6Wg08xsTzge2Cm8T5fX4O0iPzAV6k7UQiI0FXyWEnbEMsi9mT9bOwmgd78nsNdEGEH`}
                loading="lazy"
                frameorder={"0"}
                style={{
                    width: '100%',
                    height: '100vh',
                    opacity: 1
                }}
            />


        </div>

    )


}
